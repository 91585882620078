<template>
    <div>
        <h2>คืน/ตัด สินค้า</h2>
        <order-status-bar
          :labelGroups="labelGroups"
          v-model="datatable.selectedStatus"
        />
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col>
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
            </v-row>            
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"                  
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>           
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.RETOG)"
                >
                  <v-list-item-title>ใบคืนของ</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>    

            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'retog_create', params: { retog_id: 'create' } })">สร้างรายการย้าย</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/inventory/retog/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>          

          <template          
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}            
          </template>

          <template          
            v-slot:item.orderNumber="{ item }"            
          >
            <router-link
            :to="{ name: 'retog_create', params: { retog_id: item.id } }"
            >{{ item.orderNumber }}</router-link>            
          </template>

          <template          
            v-slot:item.warehouse="{ item }"
          >
            {{ !!item.warehouse ? item.warehouse.name : '-' }}     
          </template>

          <template          
            v-slot:item.supplier="{ item }"
          >
            {{ !!item.supplier ? item.supplier.name : '-' }}     
          </template>

          <template          
            v-slot:item.creator="{ item }"
          >
            {{ !!item.creator ? item.creator.name + ' ' + item.creator.lastname : '-' }}     
          </template>

          <template          
            v-slot:item.status="{ item }"
          >            
            <v-chip
              :color="item.status | orderStatusToColor"
              label
            >
              {{ item.status | orderStatusToText }}
            </v-chip>                                      
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>{{ mdiMenu }}</v-icon>                  
                </v-btn>
              </template>
              <v-list>

                <v-list-item @click="printSingle($PRINT_TYPE.RETOG, item.id)">
                  <v-list-item-icon>
                    <v-icon>{{mdiPrinter}}</v-icon>
                  </v-list-item-icon>                  
                  <v-list-item-title class="ml-2">ใบคืนของ</v-list-item-title>
                </v-list-item>         
                

                <v-divider></v-divider>

                <v-list-item @click="$router.push({name: 'retog_create', params: { retog_id: item.id }})">                  
                  <v-list-item-icon>
                    <v-icon>
                      {{ mdiEyeOutline  }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ดู</v-list-item-title>
                </v-list-item>

                <template v-if="$store.getters['auth/GET_ABILITY'].can('update', 'Inventory') && item.status == 'returned'">
                  <v-divider></v-divider>
                
                  <v-list-item @click="cancelOrder(item.id, item.orderNumber)">                  
                    <v-list-item-icon>
                      <v-icon>
                      {{ mdiDelete }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ยกเลิก</v-list-item-title>
                  </v-list-item>
                </template>                

              </v-list>
            </v-menu>            
          </template>
          </delay-datatable>
        </v-card>
    </div>
</template>
<script>
import { RETOGOrder } from '@/classes/order/RETOGOrder'
import OrderStatusBar from '@/components/OrderStatusBar.vue'
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete, asyncGet } from '@/helpers/asyncAxios'
import {
  mdiCashCheck,
  mdiCheck,
  mdiCloudUpload,
  mdiDelete,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
export default {
  data() {
    return {
      labelGroups: [
        [
          {
            id: 'all',
            text: 'ทั้งหมด',
            counter: 0,
            color: '#D64935',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'returned',
            text: 'คืนของแล้ว',
            counter: 0,
            color: '#6CCCA3',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'cancelled',
            text: 'ยกเลิก',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
        ],
      ],
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ใบคืนของ',
            value: 'orderNumber',
          },
          {
            text: 'วันที่สร้าง',
            value: 'created_at',
          },
          {
            text: 'จากคลัง',
            value: 'warehouse',
            sortable: false,
          },
          {
            text: 'ซัพพลายเออร์',
            value: 'supplier',
            sortable: false,
          },
          {
            text: 'สถานะ',
            value: 'status',
          },
          {
            text: 'ผู้สร้าง',
            value: 'creator',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
      mdiCheck,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        selectedStatus: this.datatable.selectedStatus,
        search: this.datatable.search,
      }
    },
  },
  created() {
    this.queryCount()
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async queryCount() {
      try {
        const { total, returned, cancelled } = await asyncGet('/inventory/retog/count')

        this.labelGroups = [
          [
            {
              id: 'all',
              text: 'ทั้งหมด',
              counter: total,
              color: '#D64935',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'returned',
              text: 'คืนของแล้ว',
              counter: returned,
              color: '#6CCCA3',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'cancelled',
              text: 'ยกเลิก',
              counter: cancelled,
              color: '#5B6573',
              textColor: 'white',
            },
          ],
        ]
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async cancelOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยกเลิก ?',
        body:
          'คุณต้องการยกเลิกบิลหมายเลข "' +
          orderNumber +
          '" ใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      this.sendingData = true
      try {
        await asyncDelete('/inventory/retog', {
          items: [id],
        })

        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.sendingData = false
    },
    async printMultiple($type) {
      const itemIds = this.datatable.selectedItems.map(item => item.id)

      try {
        const { company, data } = await asyncGet('/inventory/retog/' + $type, { items: itemIds })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => new RETOGOrder(item)),
          company: company,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async printSingle($type, id) {
      try {
        const { company, data } = await asyncGet('/inventory/retog/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => new RETOGOrder(item)),
          company: company,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
  components: {
    ProductImage,
    OrderStatusBar,
  },
}
</script>